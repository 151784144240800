import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = ({ data }) => {
  const { home_image, meta_title, meta_description } = data.homepage.data;
  return (
    <Layout>
      <SEO
        keywords={[`spencer wells`, `photographer`, `nyc`]}
        title={meta_title}
        description={meta_description}
      />
      <div className="flex justify-center items-center h-screen px-8 py-10">
        <Img
          fluid={home_image.fluid}
          alt={home_image.alt}
          className="max-h-full max-w-full md:max-w-4xl self-center w-full"
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    homepage: prismicHomepage {
      data {
        home_image {
          fluid(maxWidth: 1440) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          alt
        }
        meta_title
        meta_description
      }
    }
  }
`;

export default IndexPage;
